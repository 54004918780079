import { useCallback, useEffect, useState } from 'react'
import { useOnView } from 'app/providers/onview.provider';
import { INomadeCounter, INomadeCounterForPricing } from 'app/models/booking.model'
import DateFormatter from 'app/formatters/date.formatter';
import Session from 'app/libraries/session.lib';

export const useNomadeCounter = (userCenterId: number | undefined, currentEnterprise: number, date: string | undefined) => {
  const [nomadeCounter, setNomadeCounter] = useState<INomadeCounter | undefined>(undefined);

  useEffect(() => {
    getNomadeCounter();
  }, [currentEnterprise, date]);

  const getNomadeCounterForPricing = useCallback((): INomadeCounterForPricing | undefined => {
    if (userCenterId !== undefined) {
      const bookingDate = date ? new Date(date) : new Date();
      const user = Session.getUser();
      if (user !== undefined) {
        return {
          individualId: user.individual_id,
          center: userCenterId,
          date: DateFormatter.formatUSDateTimeJson(bookingDate),
        };
      }
    }
    return undefined;
  }, [userCenterId, date]);

  const {nomadeCounterUseMutation} = useOnView();

  const getNomadeCounter = useCallback(async () => {
    const nomadeCounterForPricing = getNomadeCounterForPricing();
    if (nomadeCounterForPricing !== undefined) {
      try {
        const response = await nomadeCounterUseMutation?.mutateAsync({
          enterpriseId: currentEnterprise,
          body: nomadeCounterForPricing,
        });
        setNomadeCounter(response as INomadeCounter);
      } catch (error) {
        console.error("Error fetching Nomade Counter", error);
      }
    }
  }, [currentEnterprise, getNomadeCounterForPricing]);

  return {
    nomadeCounter
  };
};
