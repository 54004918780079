import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import {useTranslation} from 'react-i18next'

import CenterCardComponent from 'modules/home/components/centerCard.component'
import ConciergeCardComponent from 'modules/home/components/conciergeCard.component'
import FoodCardComponent from 'modules/home/components/foodCard.component'
import {useOnLogin} from "app/providers/onlogin.provider";
import { useNomadeCounter } from 'app/hooks/useNomadeCounter'

const Home = (): JSX.Element => {
  const {t} = useTranslation();
  const { currentRole, userCenterId, currentEnterprise } = useOnLogin();
  const { nomadeCounter } = useNomadeCounter(userCenterId, currentEnterprise, undefined);
  const [isNomadeCounterValid, setIsNomadeCounterValid] = useState(false);

  useEffect(() => {
    if (nomadeCounter !== undefined && nomadeCounter.countReservation !== undefined) {
      setIsNomadeCounterValid(true);
    }
  }, [nomadeCounter]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <CenterCardComponent/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ConciergeCardComponent/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FoodCardComponent/>
        </Grid>
        <Grid item xs={12} className="text-center mt-20" >
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item>
              {!(currentRole === 'ROLE_USER' && (!isNomadeCounterValid || (isNomadeCounterValid && nomadeCounter?.nomadeLabel === '100% Nomade France'))) && (
                <button
                  type="button"
                  onClick={() => (window.location.href = '/booking-office')}
                  className="button w-174">
                  {t('common.homeView.book_office')}
                </button>
              )}
            </Grid>
            <Grid item>
            <button
                  type="button"
                  onClick={() => (window.location.href = '/booking')}
                  className="button w-174">
                {t('common.homeView.book_meeting_room')}
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Home
