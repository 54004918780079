import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Edit } from '@material-ui/icons'
import { IQuotationListItem } from 'app/models/quotations.models'
import DateFormatter from 'app/formatters/date.formatter'


const styleOptions = {
  1: { icon: <Edit />, color: 'created' },
  2: { icon: <CheckCircleIcon />, color: 'validated' }
}

export interface IProps {
  quotation: IQuotationListItem
}

const QuotationListItem = ({ quotation }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const handleClick = useCallback(() => window.open(quotation.link, '_blank'), [quotation])
  const style = useMemo(() => styleOptions[quotation.state as keyof typeof styleOptions], [])

  return (
    <div className="myCardList">
      <div className={`pointer myCardList-header ${style.color}`} onClick={handleClick}>
        <ListItem>
          <ListItemAvatar>{style.icon}</ListItemAvatar>
          <ListItemText primary={quotation.id} secondary={quotation.centerName} />
          <ArrowForwardIosIcon color={'disabled'} fontSize={'small'} />
        </ListItem>
      </div>
      <div className="myCardList-content">
        <div className="item">
          <Typography variant="overline" className="textColorMuted">
            {t('common.global.deadline')}
          </Typography>
          <Typography paragraph={true} className="mb-0">
            {DateFormatter.formatFrDateFromString(quotation.dueDate)}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default QuotationListItem
