import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Divider } from '@material-ui/core'

import { IContractDetail } from 'app/models/contracts.model'
import { useOnView } from 'app/providers/onview.provider'
import { ContractParams } from 'app/types/contracts.type'
import { useOnLogin } from 'app/providers/onlogin.provider'

import ContractDetailsHeaderComponent from 'modules/contracts/components/contractDetailsHeader.component'
import ContractDetailsInfoComponent from 'modules/contracts/components/contractDetailsInfo.component'
import ContractDetailsActionComponent from 'modules/contracts/components/contractDetailsAction.component'
import ContractDetailsMainServiceComponent from 'modules/contracts/components/contractDetailsMainService.component'
import ContractDetailsOptionComponent from 'modules/contracts/components/contractDetailsOption.component'
import ContractDetailsPdfComponent from 'modules/contracts/components/contractDetailsPdf.component'

import 'css/list.scss'
import 'modules/contracts/css/contracts_details.scss'
import {IPaymentMethod} from '../../../app/models/payments.models'
import {PaymentTypeEnum} from '../../../app/enums/payment.enum'

const ContractsDetails = (): JSX.Element => {
  const { t } = useTranslation()
  const { id } = useParams<ContractParams>()
  const { currentEnterprise, isFinishedChangeEnterprise } = useOnLogin()
  const { contractDetailsUseMutation, contractPdfUseMutation, paymentMethodListConnectedUseMutation, gcMandateConnectedLinkGetUseMutation } = useOnView()
  const [contract, setContract] = useState<IContractDetail | undefined>()
  const [redirectUrlGc, setRedirectUrlGc] = useState<string>('')
  const [havePdf, setHavePdf] = useState(false);

  const getContract = useCallback(async () => {
    if (undefined !== id && Number(id) > 0) {
      const _contract = await contractDetailsUseMutation?.mutateAsync({
        enterpriseId: currentEnterprise,
        id: Number(id)
      })
      if (undefined !== _contract) {
        try {
          const _pdf = await contractPdfUseMutation?.mutateAsync({
            enterpriseId: currentEnterprise,
            id: Number(id)
          })
          _contract.file = _pdf?.file
          setHavePdf(true);
        }
        catch (error) {
          setContract(_contract);
          setHavePdf(false);
          console.error('Error fetching contract PDF:', error)
        }
        let _paymentMethods = await paymentMethodListConnectedUseMutation?.mutateAsync({ enterpriseId: currentEnterprise, centerId: _contract.centerId})
        if (undefined !== _paymentMethods) {
          let _paymentMethodDebit = _paymentMethods.filter((paymentMethod: IPaymentMethod) => paymentMethod.active && paymentMethod.paymentType == PaymentTypeEnum.DEBIT_DIRECT)
          if(_paymentMethodDebit.length === 0) {
            let _redirectFlowGc = await gcMandateConnectedLinkGetUseMutation?.mutateAsync({enterpriseId: currentEnterprise, centerId: _contract.centerId})
            if (undefined !== _redirectFlowGc && null !== _redirectFlowGc) {
              setRedirectUrlGc(_redirectFlowGc.redirectUrl)
            }
          }
        }
      }
      setContract(_contract)
    }
  }, [currentEnterprise, id])

  useEffect(() => {
    getContract().then()
  }, [id])

  const firstEnterprise = useMemo(() => currentEnterprise, [])

  useEffect(() => {
    if (firstEnterprise !== currentEnterprise) {
      window.location.href = '/enterprises/contracts/list'
    }
  }, [isFinishedChangeEnterprise])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={1}>
          <Box p={2}>
            <div className="myCard contract_details">
              <div className="card-header list-header">
                <Link to="/enterprise/contracts/list" color="primary" className="icon-button">
                  <ArrowBackIcon fontSize="small" />
                </Link>
                <Typography variant="h2" color="primary">
                  {t('common.my_contract')} {contract?.reference}
                </Typography>
              </div>
              <div className="card-content">
                <Grid container spacing={3}>
                  <Grid item xl={3} md={4} xs={12}>
                    <ContractDetailsHeaderComponent contract={contract} />
                    <Grid container spacing={3}>
                      <ContractDetailsInfoComponent contract={contract} />
                      <Grid item xs={12} className="pt-0">
                        <Divider></Divider>
                        <ContractDetailsActionComponent contract={contract} redirectUrlGc={redirectUrlGc} havePdf={havePdf} />
                        {undefined !== contract?.services && contract?.services.length > 0 && (
                          <ContractDetailsMainServiceComponent services={contract?.services} />
                        )}
                        {undefined !== contract?.options && contract?.options.length > 0 && (
                          <ContractDetailsOptionComponent options={contract?.options} />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xl={9} md={8} xs={12}>
                    <ContractDetailsPdfComponent file={contract?.file} />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ContractsDetails
