
import LogoLogin from 'images/common/logo_login.png'
import LogoLoginWhite from 'images/common/logo_login_white.png'
import Logo from 'images/common/logo.png'
import LogoWhite from 'images/common/logo_white.png'
import BgImage from 'images/login/bgLogin.png'
import BgImageDark from 'images/login/bgLogin_dark.png'

export const IMAGES = {
    LOGO_LOGIN : LogoLogin,
    LOGO_LOGIN_WHITE : LogoLoginWhite,
    LOGO : Logo,
    LOGO_WHITE : LogoWhite,
    BG_LOGIN : BgImage,
    BG_LOGIN_DARK : BgImageDark,
}